import styled from "styled-components";

export default function ContractHeader({device, data}) {
	const array = [
		{
			"link": process.env.CONTRACT_LINK,
			"title": data.ape_contract
		},
		{
			"link": process.env.MUTANT_CONTRACT_LINK,
			"title": data.mutant_contract
		}
	]

	const renderItems = () => {
		return (
			array.map((item, index) => {
				return (
					<li key={index}><a rel="noreferrer" href={item.link} target="_blank">{item.title}</a></li>
				)
			})
		)
	}
	return (
		device === 'mobile' ? (
			<MobileContract>
				<ul>
					{renderItems()}
				</ul>
			</MobileContract>
		) : (
			<ContractHeaderWrapper>
				<button>{data.contracts}</button>
				<ul className="drop">
					{renderItems()}
				</ul>
			</ContractHeaderWrapper>
		)
	)
}

const MobileContract = styled.div`
	margin-top: 30px;
	border-top: 1px solid #d1df3f;
	padding-top: 15px;
	width: 100%;
	li{
		margin-bottom: 10px;
		a{
			font-size: 18px;
		}
	}
`

const ContractHeaderWrapper = styled.div`
	position: relative;
	margin-left: 10px;
	&:hover{
		.drop{
			opacity: 1;
			visibility: visible;
			transition: all 0.3s ease-in-out;
		}
	}
	button{
		width: 110px;
		background: #ffffff;
		padding: 10px 30px;
		border-radius: 10px;
		text-transform: uppercase;
		font-weight: bold;
		height: 40px;
	}
	.drop{
		position: absolute;
		top: 0;
		opacity: 0;
		visibility: hidden;
		right: 0;
		padding-top: 45px;
		transition: all 0.3s ease-in-out;
		li{
			width: 180px;
			background-color: #ffffff;
			padding: 5px 20px;
			text-align: right;
			a{
				color: #111113;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 14px;
				&:hover{
					color: #D80027;
				}
			}
			&:first-child{
				padding: 10px 20px 5px 20px;
				border-radius: 5px 5px 0 0;
			}
			&:last-child{
				border-radius: 0 0 5px 5px;
				padding: 5px 20px 10px 20px;
				margin-bottom: 0;
			}
		}
	}
`
