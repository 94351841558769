import {useSnapshot} from "valtio";
import {state} from "state";
import {useEffect, useState} from "react";
import Notification from "components/Notification";

export default function ConnectNotification() {
	const [visible, setVisible] = useState(false);
	const snap = useSnapshot(state);

	useEffect(() => {
		if (snap.login && snap.loginMessage) {
			setVisible(true)
			state.loginMessage = false
		}
	}, [snap.login])

	return (
		<Notification active={visible} onCallBack={setVisible}>
			Successful connection to the wallet
		</Notification>
	)
}
