import styled from "styled-components";
import {useEffect, useState} from "react";
import {motion} from "framer-motion";
import useClickOutside from 'click-outside-hook';

export default function Notification({active, children, onCallBack, timer = 3000}) {
	const [visible, setVisible] = useState(false)
	const ref = useClickOutside(() => setVisible(false));

	useEffect(() => {
		if (active) {
			setVisible(true)
			setTimeout(() => {
				setVisible(false)
			}, timer)
		} else onCallBack(false)
	}, [active])

	return (
		<NotificationWrapper
			ref={ref}
			initial={{x: 300}}
			animate={{x: visible ? 0 : 300}}
			transition={{duration: .3}}
		>
			{children}
		</NotificationWrapper>
	)
}

const NotificationWrapper = styled(motion.div)`
	position: fixed;
	max-width: 300px;
	width: 100%;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 20px 0 0 20px;
	z-index: 99999;
	top: 20px;
	right: 0;
	color: #111113;
	font-weight: bold;
	font-size: 16px;
	border-right: 10px solid #00563B;
	@media only screen and (max-width: 600px) {
		top: auto;
		bottom: 20px;
	}
`
