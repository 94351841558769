import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Logo from "components/Logo";
import Link from "next/link";
import {useRouter} from "next/router";
import Image from "next/image";
import useScrollPosition from '@react-hook/window-scroll'
import {useEffect, useState} from "react";
import {useSnapshot} from "valtio";
import {state} from "state";
import Social from "components/Social";
import Responsive from "helpers/Responsive";
import ConnectNotification from "components/ConnectNotification";
import ContractHeader from "components/ContractHeader";
import LangSwitcher from "components/LangSwitcher";

export default function Header(){
	const [mobile, setMobile] = useState(false);
	const snap = useSnapshot(state);
	const [visible, setVisible] = useState(false)
	const scrollY = useScrollPosition(60)
	const {asPath} = useRouter()

	const {locale} = useRouter();
	const data = {...require(`language_component/header/${locale}.json`)}

	useEffect(() => {
		setMobile(false)
	}, [asPath])

	useEffect(() => {
		if (scrollY > 30) {
			setVisible(true)
		} else setVisible(false)
	}, [scrollY])

	const renderButtons = (device) => {
		return (
			<div className="header_buttons">
				<div className="mint">
					<Link href="/mint"><a>{data.mint_mutant_link}</a></Link>
				</div>
				<ContractHeader data={data.contract} device={device}/>
			</div>
		)
	}

	const renderNav = () => {
		return (
			data.main_nav.map((item, index) => {
				return (
					item.drop ? (
						<li className={`drop_menu_li ${`/${asPath.split('/')[1]}` === item.slug ? 'active' : ''}`} key={index}>
							<span>{item.title}</span>
							<ul className="drop_menu">
								{
									item.drop.map((dropItem, dropIndex) => {
										return <li key={dropIndex}><Link href={dropItem.slug}><a>{dropItem.title}</a></Link></li>
									})
								}
							</ul>
						</li>
					) : (
						<li className={`/${asPath.split('/')[1]}` === item.slug ? 'active' : ''} key={index}>
							<Link href={item.slug}><a>{item.title}</a></Link>
						</li>
					)
				)
			})
		)
	}

	return (
		<>
			<ConnectNotification/>
			<MobileNav className={mobile ? 'active' : ''}>
				<button onClick={() => setMobile(false)} className="close">
					<svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.61654 7.50001L14.7688 1.34772C15.0771 1.0394 15.0771 0.539532 14.7688 0.23124C14.4605 -0.0770801 13.9606 -0.0770801 13.6523 0.23124L7.50001 6.38353L1.34772 0.23124C1.0394 -0.0770801 0.539532 -0.0770801 0.23124 0.23124C-0.0770508 0.539561 -0.0770801 1.03942 0.23124 1.34772L6.38353 7.50001L0.23124 13.6523C-0.0770801 13.9606 -0.0770801 14.4605 0.23124 14.7688C0.539561 15.0771 1.03942 15.0771 1.34772 14.7688L7.50001 8.61648L13.6523 14.7688C13.9606 15.0771 14.4605 15.0771 14.7688 14.7688C15.0771 14.4604 15.0771 13.9606 14.7688 13.6523L8.61654 7.50001Z" fill="#ffffff"/>
					</svg>
				</button>
				<CenterBlock>
					<ul>
						<li><Link href="/"><a>{data.mobile_home}</a></Link></li>
						{
							data.main_nav.map((item, index) => {
								return (
									item.drop ? (
										item.drop.map((dropItem, keyItem) => {
											return (
												<li className={asPath === dropItem.slug ? 'active' : ''} key={keyItem}>
													<Link href={dropItem.slug}><a>{dropItem.title}</a></Link>
												</li>
											)
										})
									) : (
										<li className={asPath === item.slug ? 'active' : ''} key={index}>
											<Link href={item.slug}><a>{item.title}</a></Link>
										</li>
									)
								)
							})
						}
					</ul>
					<Responsive width={768} mobileItem={renderButtons('mobile')}/>
				</CenterBlock>
			</MobileNav>
			<HeaderWrapper className={visible ? 'active' : ''}>
				<CenterBlock>
					{!visible && (
						<UserInformation>
							{snap.balance ? (
								<div className="balance">
									<span>{snap.balance}</span> TRX <img src="/pic/trx-icon.svg" width={20} height={20} alt="TRX"/>
								</div>
							) : <div className="balance">{snap.tronWallet ? 'connecting to wallet ...' : (
								<div className="description_balance">{data.wallet_not_connected} <a rel="noreferrer" target="_blank" href="https://telegra.ph/How-To-Mint-Bored-Apes-Yacht-Club-Tron-09-04">({data.user_guide})</a></div>
							)}</div>}
							<Social/>
						</UserInformation>
					)}
					<MainNavigation>
						<Logo/>
						<div className="navigation">
							<ul className="main_nav">
								{renderNav()}
							</ul>
							<Responsive width={600} desktopItem={renderButtons('desktop')}/>
							<LangSwitcher/>
							<MobileButton onClick={() => setMobile(true)}>
								<span/>
								<span/>
								<span/>
							</MobileButton>
						</div>
					</MainNavigation>
				</CenterBlock>
			</HeaderWrapper>
			<MainHeadBlock className={asPath === "/" ? 'main_page' : ''}>
				<CenterBlock>
					<Image
						priority={true}
						src="/pic/main-head.jpg"
						layout="fill"
						objectFit="cover"
						objectPosition="top center"
						alt="main header background"
					/>
				</CenterBlock>
			</MainHeadBlock>
		</>
	)
}

const MobileNav = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: #111113;
	top: 0;
	left: 100vw;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	.social{
		margin-top: 20px;
	}
	&.active{
		left: 0;
		visibility: visible;
		transition: all 0.3s ease-in-out;
	}
	.mint{
		margin-top: 30px;
		a {
			background: #d1df3f;
			color: #111113;
		}
	}
	.mint{
		a{
			padding: 10px 30px;
			border-radius: 10px;
			text-transform: uppercase;
			font-weight: bold;
		}
	}
	.close{
		position: absolute;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		top: 40px;
		right: 30px;
		z-index: 100;
		@media only screen and (max-width: 600px) {
			top: 20px;
			right: 20px;
		}
	}
	.center_block{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	li{
		text-align: center;
		margin-bottom: 15px;
		&.active{
			a{
				color: #D80027;
			}
		}
		&:last-child{
			margin-bottom: 0;
		}
		a{
			font-weight: bold;
			font-size: 23px;
			color: #ffffff;
			text-transform: uppercase;
		}
	}
`

const MobileButton = styled.button`
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 45px;
	height: 40px;
	background-color: #ffffff;
	padding: 12px 10px;
	margin-left: 10px;
	border-radius: 5px;
	@media only screen and (max-width: 768px) {
		display: flex;
	}
	span{
		display: block;
		width: 100%;
		border-bottom: 2px solid #111113;
		border-radius: 2px;
	}
`

const MainHeadBlock = styled.div`
	width: 100%;
	background: #070017;
	height: 240px;
	z-index: 10;
	position: absolute;
	top: 0;
	&.main_page{
		height: 440px;
		@media only screen and (max-width: 600px) {
			height: 300px;
		}
	}
`

const UserInformation = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	.description_balance{
		a{
			margin-left: 5px;
		}
	}
	.balance{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 10px;
		border-right: 1px solid #ffffff;
		padding-right: 10px;
		a{
			color: #ffffff;
			text-decoration: underline;
			margin-right: 5px;
		}
	}
	p{
		margin: 0;
	}
	span{
		margin: 0 5px;
		display: block;
		font-weight: bold;
		text-transform: uppercase;
	}
	img{
		margin-left: 5px;
	}
`

const MainNavigation = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	.contract{
		margin-left: 10px;
	}
	.mint{
		margin-left: 20px;
	}
	.mint a{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		border-radius: 10px;
		background: #d1df3f;
		padding: 0 15px;
		font-weight: bold;
		color: #111113;
		font-size: 14px;
		text-transform: uppercase;
	}
	.contract a{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		border-radius: 10px;
		background: #ffffff;
		padding: 0 15px;
		font-weight: bold;
		color: #111113;
		font-size: 14px;
		text-transform: uppercase;
	}
	.navigation, .header_buttons{
		display: flex;
		align-items: center;
	}
	.drop_menu_li{
		&:hover{
			.drop_menu{
				opacity: 1;
				visibility: visible;
				transition: all 0.3s ease-in-out;
			}
		}
	}
	.drop_menu{
		position: absolute;
		top: 0;
		opacity: 0;
		visibility: hidden;
		padding-top: 25px;
		transition: all 0.3s ease-in-out;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
		li{
			width: 200px;
			background: #ffffff;
			padding: 5px 20px;
			&:first-child{
				padding: 10px 20px 5px 20px;
				border-radius: 5px 5px 0 0;
			}
			&:last-child{
				border-radius: 0 0 5px 5px;
				padding: 5px 20px 10px 20px;
			}
			a{
				color: #111113 !important;
				&:hover{
					color: #D80027 !important;
				}
			}
		}
	}
	.main_nav{
		display: flex;
		align-items: center;
		@media only screen and (max-width: 768px) {
			display: none;
		}
		li{
			margin-right: 20px;
			position: relative;
			&:last-child{
				margin-right: 0;
			}
			&.active {
				a, span {
					color: #D80027;
					&:hover {
						color: #D80027;
					}
				}
			}
			a, span{
				font-weight: bold;
				font-size: 14px;
				line-height: 17px;
				text-transform: uppercase;
				color: #FFFFFF;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				&:hover {
					color: #D80027;
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}
`

const HeaderWrapper = styled.header`
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	background: transparent;
	transition: .3s ease-in-out;
	padding-bottom: 20px;
	backdrop-filter: blur(0);
	&.active{
		background: rgba(0, 0, 0, .7);
		backdrop-filter: blur(12px);
		padding-bottom: 10px;
		.logo{
			a{
				font-size: 18px;
			}
		}
	}
`
