import styled from "styled-components";
import Link from "next/link";
import {useRouter} from "next/router";
import {useState} from "react";
import useClickOutside from 'click-outside-hook';
import Image from "next/image";

export default function LangSwitcher() {
	const ref = useClickOutside(() => setVisible(false));
	const [visible, setVisible] = useState(false);
	const {asPath, locale} = useRouter();
	const array = [
		{
			"slug": "en",
			"flag": "/pic/lang/en.png"
		},
		{
			"slug": "cn",
			"flag": "/pic/lang/cn.png"
		},
		{
			"slug": "kr",
			"flag": "/pic/lang/kr.png"
		},
		{
			"slug": "ru",
			"flag": "/pic/lang/ru.png"
		}
	]
	return (
		<LangSwitcherWrapper ref={ref}>
			<button onClick={() => setVisible(!visible)}><Image width={20} height={20} priority={true} src={array.find(item => item.slug === locale).flag} alt={locale}/></button>
			<ul className={visible ? 'active' : ''}>
				{
					array.map((item, index) => {
						return (
							item.slug !== locale && (
								<li onClick={() => setVisible(false)} key={index}>
									<Link href={asPath} locale={item.slug}>
										<a><Image priority={true} width={20} height={20} src={item.flag} alt={item.slug}/></a>
									</Link>
								</li>
							)
						)
					})
				}
			</ul>
		</LangSwitcherWrapper>
	)
}

const LangSwitcherWrapper = styled.div`
	position: relative;
	width: 60px;
	margin-left: 10px;
	z-index: 100;
	@media only screen and (max-width: 600px) {
		margin-left: 0;
	}
	button{
		background: #fff;
		border-radius: 10px;
		height: 40px;
		img{
			width: 25px;
			height: 25px;
			border: 1px solid #ebebeb;
			border-radius: 50%;
		}
	}
	ul{
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		background-color: #ffffff;
		border-radius: 10px;
		transition: all 0.3s ease-in-out;
		box-shadow: 0 0 10px rgba(0, 0, 0, .4);
		&.active{
			opacity: 1;
			visibility: visible;
			transition: all 0.3s ease-in-out;
		}
		li{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 40px;
			border-bottom: 1px solid #ebebeb;
			&:last-child{
				border-bottom: none;
			}
			img{
				width: 25px;
				height: 25px;
				border: 1px solid #ebebeb;
				border-radius: 50%;
			}
		}
	}
`
