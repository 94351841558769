import styled from "styled-components";
import Link from "next/link";

export default function Logo(){
	return (
		<LogoWrapper className="logo">
			<Link href="/"><a>BAYC<span>Tron.com</span></a></Link>
		</LogoWrapper>
	)
}

const LogoWrapper = styled.div`
	a{
		font-weight: bold;
		font-size: 30px;
		line-height: 36px;
		color: #ffffff;
		transition: none;
		@media only screen and (max-width: 600px) {
			font-size: 20px;
		}
		&:hover{
			span{
				color: #ebebeb;
			}
		}
		span{
			color: #D80027;
		}
	}
`
